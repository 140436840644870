import apiClient from "@/api/apiClient";

export const getAvailability = async (branch_id, product_id, market) => {
  const url =
    market === "halyk" ? "/api/halyk-availability" : "/api/availability";
  const result = await apiClient.get(url, {
    params: {
      branch_id,
      product_id,
    },
  });
  return result.data;
};

export const saveAvailability = async (payload) => {
  const url =
    payload.market === "halyk"
      ? "/api/halyk-availability"
      : "/api/availability";
  const result = await apiClient.post(url, payload);
  return result.data;
};
