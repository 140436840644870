import apiClient from "@/api/apiClient";

export const getProducts = async (filters) => {
  const result = await apiClient.get("/api/products", {
    params: {
      ...filters,
    },
  });
  return result.data;
};

export const getAllProducts = async () => {
  const result = await apiClient.get("/api/products-all");
  return result.data;
};

export const searchProducts = async (query) => {
  const result = await apiClient.get("/api/products", {
    params: {
      action: "search",
      query,
    },
  });
  return result.data.products;
};

export const importProducts = async (file_url) => {
  const result = await apiClient.post("/api/products", {
    file_url,
  });
  return result.data;
};

export const importFormpProducts = async (file_url) => {
  const result = await apiClient.post("/api/products/kaspi", {
    file_url,
  });
  return result.data;
};

export const importHalykProducts = async (file_url) => {
  const result = await apiClient.post("/api/products/halyk", {
    file_url,
  });
  return result.data;
};

export const getProduct = async (article) => {
  const result = await apiClient.get(`/api/product/${article}`);
  return result.data;
};

export const createProduct = async (product) => {
  const result = await apiClient.post("/api/product/new", product);
  return result.data;
};

export const updateProduct = async (productId, product) => {
  const result = await apiClient.put(`/api/product/${productId}`, product);
  return result.data;
};

export const competitorAnalysis = async (productId, cityId) => {
  const result = await apiClient.post(`/api/product/${productId}`, {
    action: "competitor_analysis",
    city_id: cityId,
  });
  return result.data;
};

export const deleteProduct = async (productId) => {
  const result = await apiClient.delete(`/api/product/${productId}`);
  return result.data;
};

export const parseKaspiLog = async (logs) => {
  const result = await apiClient.post(`/api/kaspi/logs/parse`, {
    logs,
  });
  return result.data;
};

export const removeFromSale = async (ids) => {
  try {
    const result = await apiClient.post(`/api/products/group`, {
      action: "remove_from_sale",
      ids,
    });
    return {
      success: result.data.success,
    };
  } catch (e) {
    return {
      success: false,
    };
  }
};

export const removeFromDamping = async (ids) => {
  try {
    const result = await apiClient.post(`/api/products/group`, {
      action: "remove_from_damping",
      ids,
    });
    return {
      success: result.data.success,
    };
  } catch (e) {
    return {
      success: false,
    };
  }
};
