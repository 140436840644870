<template>
  <div class="bg-gray-100 border-t relative">
    <div
      class="absolute left-5 top-2 rounded-xl p-0.5 px-2 text-xs"
      :class="{
        'bg-red-400': market === 'kaspi',
        'bg-green-400': market === 'halyk',
      }"
    >
      {{ market }}
    </div>
    <el-row>
      <el-col :span="6">
        <div class="p-5 pt-7 font-semibold text-md">
          {{ branch.title }} ({{
            market === "kaspi" ? branch.branch_code : branch.branch_code_halyk
          }}) <br />
          <template v-if="updated_at">
            <br />
            Обновлен в: {{ dateTimeFormat(updated_at) }}
          </template>
          <div v-if="dealerPrice">Маржа: {{ margin }}</div>
        </div>
        <div v-if="market === 'kaspi'" class="px-5">
          <div
            class="ks-widget mb-5"
            data-template="button"
            :data-merchant-sku="sku"
            :data-merchant-code="user.merchant_id"
            :data-city="branch.city_id"
          ></div>
        </div>
      </el-col>
      <el-col :span="18">
        <div class="border-l p-5">
          <el-row :gutter="20">
            <el-col :span="6" :xs="24">
              <div class="mb-3">
                <p class="text-gray-500 mb-1">Доступно</p>
                <el-checkbox v-model="form.enable"></el-checkbox>
              </div>
            </el-col>
            <el-col :span="6" :xs="24">
              <div class="mb-3" :class="{ 'has-error': $v.form.price.$error }">
                <p class="text-gray-500 mb-1">Цена</p>
                <el-input
                  :disabled="!form.enable"
                  type="number"
                  size="small"
                  v-model.number="$v.form.price.$model"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="market === 'halyk' ? 4 : 6" :xs="24">
              <div
                class="mb-3"
                :class="{ 'has-error': $v.form.pre_order.$error }"
              >
                <p class="text-gray-500 mb-1">Предзаказ</p>
                <el-input
                  :disabled="!form.enable"
                  type="number"
                  size="small"
                  v-model.number="$v.form.pre_order.$model"
                ></el-input>
              </div>
            </el-col>
            <el-col v-if="market === 'halyk'" :span="4" :xs="24">
              <div class="mb-3">
                <p class="text-gray-500 mb-1">Loan period</p>
                <el-input
                  :disabled="!form.enable"
                  type="number"
                  size="small"
                  v-model.number="form.loan_period"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="6" :xs="24">
              <div class="mb-3">
                <p class="text-gray-500 mb-1">Комиссия(%)</p>
                <el-input
                  :disabled="!form.enable"
                  type="number"
                  v-model.number="form.tax"
                  size="small"
                ></el-input>
              </div>
            </el-col>
          </el-row>

          <template v-if="market === 'kaspi'">
            <damping-settings
              v-if="showDamping && form.enable"
              ref="damping-settings"
              @error="invalidForm = true"
              :damping="damping"
            ></damping-settings>
          </template>

          <div class="text-right">
            <el-button @click="save" round plain type="primary" size="small">
              Сохранить
            </el-button>
          </div>

          <div v-if="offer">
            <div
              @click="showOffers = !showOffers"
              class="cursor-pointer inline-block"
            >
              Обновлен в: {{ dateTimeFormat(offer.last_updated) }}
              <i v-if="!showOffers" class="el-icon-arrow-right"></i>
              <i v-else class="el-icon-arrow-down"></i>
            </div>
            <el-table
              v-if="showOffers"
              class="mt-3"
              :data="offer.offers.offers"
              border
              style="width: 100%"
              ><el-table-column
                type="index"
                label="N"
                align="center"
              ></el-table-column>
              <el-table-column prop="name" label="Название магазина">
                <template v-slot="scope">
                  {{ scope.row.merchantName }} <br />
                  {{ scope.row.merchantId }}
                </template>
              </el-table-column>
              <el-table-column prop="price" label="Цена"> </el-table-column>
            </el-table>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getAvailability, saveAvailability } from "@/api/availability";
import { dateTimeFormat, numberFormat } from "@/helpers/formatter";
import DampingSettings from "@/components/DampingSettings";
import { validationMixin } from "vuelidate";
import { required, minValue, integer } from "vuelidate/lib/validators";

export default {
  name: "BranchAvailability",
  mixins: [validationMixin],
  components: {
    DampingSettings,
  },
  props: ["branch", "sku", "market", "dealerPrice"],
  data: () => ({
    updated_at: null,
    offer: null,
    showOffers: false,
    form: {
      enable: false,
      price: null,
      pre_order: null,
      loan_period: 0,
      tax: 0,
    },
    damping: {
      damping: false,
      min_price: null,
      step: null,
      interval_type: "mid",
    },
    invalidForm: false,
  }),
  computed: {
    user() {
      return this.$store.state.user;
    },
    product_id() {
      return +this.$route.params.id;
    },
    showDamping() {
      return location.hostname !== "app.formp.kz";
    },
    margin() {
      return (this.form.price * (100 - this.form.tax)) / 100 - this.dealerPrice;
    },
  },
  validations() {
    if (this.form.enable) {
      return {
        form: {
          price: { required, integer, minValue: minValue(1) },
          pre_order: { required, integer, minValue: minValue(0) },
        },
      };
    }
    return {
      form: {
        price: {},
        pre_order: {},
      },
    };
  },
  async created() {
    const result = await getAvailability(
      this.branch.id,
      this.product_id,
      this.market
    );
    if (result.availability) {
      let { price, pre_order, loan_period, tax } = result.availability;
      let { damping, min_price, step, interval_type } = result.availability;
      this.form = { enable: true, price, pre_order, loan_period, tax };
      this.damping = {
        damping,
        min_price,
        step,
        interval_type: interval_type || "mid",
      };
      this.updated_at = result.availability.updated_at;
    }
    if (result.offer) {
      this.offer = result.offer;
    }
    console.log("result", result);
  },
  methods: {
    async save() {
      this.invalidForm = false;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.invalidForm = true;
      }
      if (this.$refs["damping-settings"]) {
        this.$refs["damping-settings"].validate();
      }
      if (this.invalidForm) {
        this.$message.error("Ошибка валидации форм");
        return;
      }
      try {
        const result = await saveAvailability({
          ...this.form,
          branch_id: this.branch.id,
          product_id: this.product_id,
          ...this.damping,
          market: this.market,
        });
        if (result.success) {
          this.$message.success("Успешно");
        } else {
          this.$message.error(result.message || "Error");
        }
      } catch (e) {
        console.log(e);
        this.$message.error("Ошибка");
      }
    },
    numberFormat(number) {
      return numberFormat(number);
    },
    dateTimeFormat(datetime) {
      return dateTimeFormat(datetime * 1000);
    },
  },
};
</script>

<style scoped></style>
